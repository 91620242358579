<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="add_new_product" aria-labelledby="sidebar-no-header-title" no-header backdrop width="700px" style="direction:ltr;padding:5px;" right title="اضافة منتج" shadow >
                <template #default="{ hide }" >
                <div class="m-1 text-start" style="direction:rtl;margin:5px;">
                    <div style="width:130px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>اضافة / تعديل منتج</span>
                    </div>
                    <div @click="hide" id="hidecrmprod" style="text-align:center;width:100px;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>اغلاق</span>
                </div>
                </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl;margin:5px;" class="m-2">
                    <v-col cols="12" md="3" sm="12">
                        <v-text-field
                            label="رقم الصنف"
                            v-model="product.item_code"
                            @change="getItem()"
                            >{{ product.item_code }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="9" sm="12">
                        <v-text-field
                            label="اسم الصنف"
                            v-model="product.name"
                            >{{ product.name }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <v-text-field
                            label="الكمية الحالية"
                            v-model="product.qty"
                            type="number"
                            >{{ product.qty }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            label="سعر البيع"
                            v-model="product.price"
                            type="number"
                            >{{ product.name }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            label="التكلفة الافتراضية"
                            v-model="product.cost"
                            type="number"
                            >{{ product.cost }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="product.catid"
                            :items="productcats"
                            label="التصنيف"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" sm="12" style="direction:rtl;margin:5px;">
                        <b-table-simple>
                            <thead>
                                <tr>
                                    <th colspan="5">المواد الأولية</th>
                                </tr>
                                <tr>
                                    <th>رقم الصنف</th>
                                    <th>اسم الصنف</th>
                                    <th>سعر البيع</th>
                                    <th>التكلفة الافتراضية</th>
                                    <th>الكمية المتوفرة</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in alternatives" :key="index">
                                    <td>{{item.item_code}}</td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.price}}</td>
                                    <td>{{item.cost}}</td>
                                    <td>{{item.qty}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>رقم الصنف البديل</th>
                                    <th colspan="2">
                                        <v-text-field
                                            label="رقم الصنف"
                                            v-model="item_code"
                                            @change="getAlternative()"
                                            >{{ item_code }}</v-text-field>
                                    </th>
                                    <th colspan="2">

                                    </th>
                                </tr>
                            </tfoot>
                        </b-table-simple>
                    </v-col>
                </v-row>
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addProd()" variant="success" class="ma-2" style="width:100px;">حفظ</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            item_code: '',
            productsname: [],
            product: {
                item_code: '',
                name: '',
                qty: 1,
                price: 0,
                type: 1,
                cost: 0,
                catid: 0,
            },
            productcats: [],
            alternatives: [],
        }
    },
    methods: {
        getCats(){
            const post = new FormData();
            post.append("type","getPCatsList");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',"all0");
            post.append('data[lang]',"ar");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                const p = res.data;
                console.log('asdfasdf',p);
                let t = [];
                if(typeof p.results !== 'undefined' && typeof p.results.data !== 'undefined'){
                    if(p.results.data.length != 0){
                        for(let i=0;i<p.results.data.length;i++){
                            t.push({
                                text: p.results.data[i].namear,
                                value: p.results.data[i].id
                            });
                        }
                    }
                }
                this.productcats = t;
            });
        },
        resetAll(){
            
            this.item_code = '';
            this.productsname = [];
            this.product = {
                item_code: '',
                name: '',
                qty: 1,
                price: 0,
                type: 1,
                cost: 0,
                catid: 0,
            };
            // this.productcats = [];
            this.alternatives = [];
        },
        getItem() {
            console.log("I am resting");
            const itemcode = this.product.item_code;
            const post = new FormData();
            post.append("type","getItemBycode");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[itemcode]",itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                console.log(res);
                if(res.results.data.results.length > 0){
                    this.product.item_code = res.results.data.results[0].itemcode;
                    this.product.name = res.results.data.results[0].name;
                    this.product.qty = res.results.data.results[0].qty;
                    this.product.price = res.results.data.results[0].price;
                    this.product.cost = res.results.data.results[0].cost;
                    this.product.catid = res.results.data.results[0].catid;
                    this.product.type = res.results.data.results[0].type;
                    this.alternatives = res.results.data.results[0].alternative;
                }
            });
        },
        getAlternative(){
            const itemcode = this.item_code;
            const post = new FormData();
            post.append("type","getItemBycode");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[itemcode]",itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                console.log(res);
                this.alternatives.push({
                    item_code: res.results.data.results[0].itemcode,
                    name: res.results.data.results[0].name,
                    price: res.results.data.results[0].price,
                    qty: res.results.data.results[0].qty,
                    cost: res.results.data.results[0].cost,
                });
                this.item_code = '';
            });
        },
        getProducts(){
            const post = new FormData();
            post.append("type","getallproducts");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[all]","1");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                if(res.results.data.length > 0){
                    for(let i=0;i<res.results.data.length;i++){
                        this.productsname.push(res.results.data[i])
                    }
                }
            });
        },
        addProd() {
            const post = new FormData();
            post.append("type" , "addNewProduct");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[item_code]",this.product.item_code);
            post.append("data[name]",this.product.name);
            post.append("data[qty]",this.product.qty);
            post.append("data[price]",this.product.price);
            post.append("data[type]",this.product.type);
            post.append("data[cost]",this.product.cost);
            post.append("data[catid]",this.product.catid);
            for(let i=0;i<this.alternatives.length;i++){
                post.append("data[alternatives]["+i+"]",this.alternatives[i].item_code);
            }
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((response) => {
                const res = response.data;
                console.log(res);
                document.getElementById('hidecrmprod').click();
                this.$parent.getProducts();
            });
        },
        
    },
    created() {
        this.getCats();
    },
}
</script>
        
 